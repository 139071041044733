const farmMachinery = '/farmMachinery/config/farmer-info/'
export const machineryFarmerAllInfo = farmMachinery + 'mac-farmer-info'
const application = 'external-user/application/'
export const circInstrList = application + 'circular-instruments'
export const applicationStore = application + 'store'

// Application Api Routes
export const subApplocationList = '/dae/subsidy/application/list'

// Approved Application Details Api Routes
export const approvedApplicationDetails = '/dae/subsidy/application/approved-show'
// qrcode api
export const allocationDistributionQrcodeAPi = '/farmMachinery/allocation/allocation-distribution-qrcode'
